const version = '1.0.1';
const dataSource = 'data/geojson/';
//const dataSource = 'https://easydivepoints.carbonarm.com/modules/spro_easydive_points/data/geojson/';
import Feature from 'ol/Feature.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import Map from 'ol/Map.js';
import View from 'ol/View.js';
import Overlay from 'ol/Overlay.js';
import OSM from 'ol/source/OSM.js';
import Geolocation from 'ol/Geolocation.js';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'elm-pep';
//import PleaseRotate from '../assets/libs/pleaserotate.min.js';

import {
  Circle as CircleStyle,
  Fill,
  Icon,
  Stroke,
  Style,
  Text,
} from 'ol/style.js';
import {
  Control,
  ZoomSlider,
  //FullScreen,
  defaults as defaultControls,
} from 'ol/control.js';
import { Cluster, Vector as VectorSource } from 'ol/source.js';
//import { LineString, Point } from 'ol/geom.js';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js';
import { createEmpty, extend, getHeight, getWidth } from 'ol/extent.js';
import { Point } from 'ol/geom.js';
import { getUid } from 'ol/util';
import { fromLonLat } from 'ol/proj.js';

const Config = {
  clusterMemberStyle: {
    resize: 0.4,
    opacity: 1
  },
  outerCircleFill: {
    color: 'rgba(197,42,39,0.1)',
  },
  innerCircleFill: {
    color: 'rgba(197,42,39,0.6)'
  },
  circleDistanceMultiplier: 1,
  circleFootSeparation: 28,
  circleStartAngle: Math.PI / 2
}
/*
const convexHullStroke = new Stroke({
  color: 'rgba(204, 85, 0, 1)',
  width: 1.5,
});
*/
const outerCircleFill = new Fill({
  color: Config.outerCircleFill.color //'rgba(197,42,39,0.1)', //'rgba(255, 153, 102, 0.3)',
});
const innerCircleFill = new Fill({
  color: Config.innerCircleFill.color //'rgba(197,42,39,0.6)', //'rgba(255, 165, 0, 0.7)',
});
const textFill = new Fill({
  color: '#fff',
});
const textStroke = new Stroke({
  color: 'rgba(0, 0, 0, 0.6)',
  width: 3,
});
const innerCircle = new CircleStyle({
  radius: 12,
  fill: innerCircleFill,
});
const outerCircle = new CircleStyle({
  radius: 16,
  fill: outerCircleFill,
});


/**
 * Single feature style, users for clusters with 1 feature and cluster circles.
 * @param {Feature} clusterMember A feature from a cluster.
 * @return {Style} An icon style for the cluster member's location.
 */
function clusterMemberStyle(clusterMember) {
  const image = new Icon({
    src: (!!clusterMember.get('IMAGE'))
      ? 'assets/images/' + clusterMember.get('IMAGE')
      : 'assets/icons/' + ['generic', 'ambassador', 'referral', 'dive-center', 'dive-school', 'easydive'][clusterMember.get('TYPE')] + '.svg',
    opacity: Config.clusterMemberStyle.opacity
  });
  const imageStyle = new Style({
    image: image
  });
  imageStyle
    .getImage()
    .setScale([
      parseFloat(Config.clusterMemberStyle.resize),
      parseFloat(Config.clusterMemberStyle.resize),
    ]);
  return new Style({
    geometry: clusterMember.getGeometry(),
    image: image,
  });
}
/*
function getLocation() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(showPosition, showError);
  }
  else { console.log("Geolocation is not supported by this browser.") }
}
function showError(error) {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      console.log("User denied the request for Geolocation.");
      break;
    case error.POSITION_UNAVAILABLE:
      console.log("Location information is unavailable.");
      break;
    case error.TIMEOUT:
      console.log("The request to get user location timed out.");
      break;
    case error.UNKNOWN_ERROR:
      console.log("An unknown error occurred.");
      break;
  }
}
//var lat, lon;
function showPosition(position) {
  const lat = position.coords.latitude;
  const lon = position.coords.longitude;

  console.log('lat', lat, 'lon', lon);
  map.getView().setCenter(fromLonLat([lon, lat], 'EPSG:3857', 'EPSG:3857'));


}
*/
let clickFeature, clickResolution;
/**
 * Style for clusters with features that are too close to each other, activated on click.
 * @param {Feature} cluster A cluster with overlapping members.
 * @param {number} resolution The current view resolution.
 * @return {Style|null} A style to render an expanded view of the cluster members.
 */
function clusterCircleStyle(cluster, resolution) {
  if (cluster !== clickFeature || resolution !== clickResolution) {
    return null;
  }
  const clusterMembers = cluster.get('features');
  const centerCoordinates = cluster.getGeometry().getCoordinates();
  return generatePointsCircle(
    clusterMembers.length,
    cluster.getGeometry().getCoordinates(),
    resolution
  ).reduce((styles, coordinates, i) => {
    /*const point = new Point(coordinates);
    const line = new LineString([centerCoordinates, coordinates]);
    styles.unshift(
      new Style({
        geometry: line,
        stroke: convexHullStroke,
      })
    );
    styles.push(
      clusterMemberStyle(
        new Feature({
          ...clusterMembers[i].getProperties(),
          geometry: point,
        })
      )
    );
    */
    return styles;
  }, []);
}

/**
 * From
 * https://github.com/Leaflet/Leaflet.markercluster/blob/31360f2/src/MarkerCluster.Spiderfier.js#L55-L72
 * Arranges points in a circle around the cluster center, with a line pointing from the center to
 * each point.
 * @param {number} count Number of cluster members.
 * @param {Array<number>} clusterCenter Center coordinate of the cluster.
 * @param {number} resolution Current view resolution.
 * @return {Array<Array<number>>} An array of coordinates representing the cluster members.
 */
function generatePointsCircle(count, clusterCenter, resolution) {
  const circumference =
    Config.circleDistanceMultiplier * Config.circleFootSeparation * (2 + count);
  let legLength = circumference / (Math.PI * 2); //radius from circumference
  const angleStep = (Math.PI * 2) / count;
  const res = [];
  let angle;

  legLength = Math.max(legLength, 35) * resolution; // Minimum distance to get outside the cluster icon.

  for (let i = 0; i < count; ++i) {
    // Clockwise, like spiral.
    angle = Config.circleStartAngle + i * angleStep;
    res.push([
      clusterCenter[0] + legLength * Math.cos(angle),
      clusterCenter[1] + legLength * Math.sin(angle),
    ]);
  }

  return res;
}

let hoverFeature;
/**
 * Style for convex hulls of clusters, activated on hover.
 * @param {Feature} cluster The cluster feature.
 * @return {Style|null} Polygon style for the convex hull of the cluster.
 */
/*
function clusterHullStyle(cluster) {
  if (cluster !== hoverFeature) {
    return null;
  }
  const originalFeatures = cluster.get('features');
  const points = originalFeatures.map((feature) =>
    feature.getGeometry().getCoordinates()
  );
  return new Style({
    geometry: new Polygon([monotoneChainConvexHull(points)]),
    fill: convexHullFill,
    stroke: convexHullStroke,
  });
}
*/

function clusterStyle(feature) {
  const size = feature.get('features').length;
  if (size > 1) {
    return [
      new Style({
        image: outerCircle,
      }),
      new Style({
        image: innerCircle,
        text: new Text({
          text: size.toString(),
          fill: textFill,
          stroke: textStroke,
        }),
      }),
    ];
  }
  const originalFeature = feature.get('features')[0];
  return clusterMemberStyle(originalFeature);
}
function removeAllTooltips() {
  const boxes = document.querySelectorAll('.bs-tooltip-auto');
  boxes.forEach(box => {
    box.remove();
  });
}
function marketsPointsToggle(map, marketsLayerId, visible) {
  //removeAllTooltips();
  let excludeId = false;
  if (marketsLayerId < 0) {
    marketsLayerId = marketsLayerId * -1;
    excludeId = true;
  }
  map.getLayers().forEach(function (layer) {
    if ((!excludeId && marketsLayerId == getUid(layer)) || (excludeId && marketsLayerId != getUid(layer))) {
      if (!(layer instanceof TileLayer)) {
        layer.setVisible(visible);
      }
    }
  });
}
function marketsPoints() {
  let iconFeatures = [];
  Object.entries(indexBase.totals.markets).forEach(market => {
    const [key, value] = market;
    if (parseFloat(value.lon) != 0 && parseFloat(value.lat) != 0
      && !isNaN(parseFloat(value.lon)) && !isNaN(parseFloat(value.lat))) {
      var iconFeature = new Feature({
        geometry: new Point(fromLonLat([value.lon, value.lat])),
        name: value.country,
        lat: value.lat,
        lon: value.lon,
        id: 'country_' + value.id_country,
        visible: true
      });
      iconFeatures.push(iconFeature);
    }
  });

  const iconLayerSource = new VectorSource({
    features: iconFeatures
  });

  const iconLayer = new VectorLayer({
    source: iconLayerSource,
    // style for all elements on a layer
    style: new Style({
      image: new Icon({
        opacity: 0.85,
        anchor: [0, 1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: [0.75, 0.75],
        src: 'assets/icons/easydive_flag42x50.png'
      })
    })
  });
  return iconLayer;
}
const popupElement = document.getElementById('popup');
const popup = new Overlay({
  element: popupElement,
  positioning: 'bottom-center',
  //stopEvent: false,
});
var popover = false;
var popoverType = false;
var popoverShown = false;

function disposePopover() {
  if (popover) {
      popover.dispose();
      popover = false;
      popoverType = false;
      popoverShown = false;      
  }
}
function popupContent(clusterMember) {
  let html = '<div class="row g-0">' +
    ((!!clusterMember.get('IMAGE'))
      ? '<div class="col-auto"><img class="round mr-1 me-1" src="assets/images/' + clusterMember.get('IMAGE') + '"></div>'
      : ''
    ) + '<div class="col-auto"><h5 class="my-0 mt-4">' + clusterMember.get('NAME') +
    ((clusterMember.get('TYPE') != '5')
      ? '<div class="image-flag-wrapper d-inline-block"><img class="image-flag ms-1" src="assets/flags/svg/' + clusterMember.get('COUNTRY_CODE') + '.svg" title="' + clusterMember.get('COUNTRY') + '"></div>'
      : ''
    ) + '</h5><span class="text-uppercase">' +
    ((!!clusterMember.get('TAGLINE'))
      ? clusterMember.get('TAGLINE')
      : ((!!clusterMember.get('CATEGORY'))
        ? clusterMember.get('CATEGORY')
        : clusterMember.get('RANK')
      )
    ) + '</span></div></div><div>' +
    ((!!clusterMember.get('NARRATIVE'))
      ? '<p class="text-justify">' + clusterMember.get('NARRATIVE') + '</p>'
      : ''
    ) +
    ((!!clusterMember.get('ADDRESS'))
      ? '<i class="bi bi-address-fill text-black"></i>' + clusterMember.get('ADDRESS') + '<br>'
      : ''
    ) +
    ((!!clusterMember.get('PHONE'))
      ? '<i class="bi bi-telephone-fill text-black me-1"></i>' + clusterMember.get('PHONE') + '<br>'
      : ''
    ) +
    ((!!clusterMember.get('EMAIL'))
      ? '<i class="bi bi-envelope-fill text-black me-1"></i>' + clusterMember.get('EMAIL') + '<br>'
      : ''
    ) +
    ((!!clusterMember.get('WEBSITE'))
      ? '<a href="' + clusterMember.get('WEBSITE') + '"><i class="bi bi-globe text-black me-1"></i>' + clusterMember.get('WEBSITE') + '</a><br>'
      : ''
    ) + ((!!clusterMember.get('EASYDIVE_LINK'))
    ? '<div class="d-grid gap-2"><a href="' + clusterMember.get('EASYDIVE_LINK') + '" class="text-uppercase text-center btn btn-danger btn-sm btn-block mt-2">' + getTranslation('Check equipment used') + '</a></div>'
    : ''
    );
    
  
  html += '<div id="popup-body-social" class="row justify-content-center m-0 mt-2">';
  ['facebook', 'instagram', 'twitter', 'vimeo', 'youtube', 'skype', 'viber', 'whatsapp'].forEach(function (key) {
    //console.log(key);
    if (!!clusterMember.get(key)) {
      let prefix = '';
      let icon = key;
      let value = clusterMember.get(key);
      switch (key) {
        case 'viber':
          icon = 'chat-dots-fill';
          break;
      }
      /*switch(key){
        case 'skype':
          if(!value.match('/^skype:/i')){
            prefix = 'skype:';
          }
          break;
          case 'whatsapp':
          if(!value.match('/^whatsapp:/i')){
            prefix = 'https://api.whatsapp.com/send?phone=';
          }
          break;
      }
      */
      html += '<div class="col-auto"><a href="' + encodeURI(prefix + value) + '" title="' + key + '"><i class="bi bi-' + icon + ' text-black"></i></a></div>';
      //html += '<div class="col-auto"><a href="'+encodeURI(value)+'"><img width="20" class="me-2" src="assets/images/social/'+key+'.svg"></a></div>';
    }
  });
  
  if (!!clusterMember.get('EASYDIVE_PRODUCTS')) {
    let products = clusterMember.get('EASYDIVE_PRODUCTS');
    if (products.length > 0) {
      /*html += '<div class="d-grid gap-2"><a href="' + clusterMember.get('EASYDIVE_LINK') + '" class="text-uppercase text-center btn btn-danger btn-sm btn-block mt-2">' + getTranslation('Equipment used') + '</a></div>';
      */
      html += '<div id="popup-body-products" class="row justify-content-center m-0 mt-2 p-3">';
      products.forEach(function (product) {
        html += '<div class="col col-sm-6 col-md-4 text-center mx-1 p-0"><a href="' + encodeURI(product.url) + '"><img src="' + product.image + '" class="w-100"><h6 class="text-center m-0 p-1">'+product.name+'</h6></a></div>';
      });
      html += '</div>';
    }
  }

  if (!!clusterMember.get('PRODUCTS')) {
    let products = clusterMember.get('PRODUCTS');
    products = products.split(',');
    if (products.length > 0) {
      html += '<div id="popup-body-products" class="row justify-content-center m-0 mt-2">';
      products.forEach(function (product) {
        let href = 'https://easydive.it/en/item-articolo.php?id=' + product;
        let src = 'images/products/' + product + '.png';
        html += '<div class="col-auto"><a href="' + encodeURI(href) + '"><img src="' + src + '"></a></div>';
      });
      html += '</div>';
    }
  }

  html += '</div></div>';
  //console.log(html);
  return html;
}
function getTranslation(text) {
  if (!!indexBase.translations[text]) {
    return indexBase.translations[text]
  } else {
    return text;
  }
}
function marketsContent() {
  document.getElementById('easydive-markets-body-a').getElementsByTagName('div')[0].innerHTML += ' <em><small>(' + Object.keys(indexBase.totals.markets).length + ')</small></em>';
  let lastContinent = false;
  let html = '<table class="first-td-allign-right">';
  Object.entries(indexBase.totals.markets).forEach(market => {
    const [key, value] = market;
    if (value.continent != null) {
      if (lastContinent != value.continent) {
        if (lastContinent != false) {
          html += '</table></td></tr>';
        }
        //count countries for continent
        html += '<tr><td>' + indexBase.totals.markets.filter(function (v) { return v.continent == value.continent }).length + '</td><td class="p-0 ps-1 collapse show">' + value.continent + '</td></tr>';
        lastContinent = value.continent;
        html += '<tr><td colspan="2"><table class="table table-borderless table-compacted m-0">';
      }
      html += '<tr><td><a href="javascript:void(0)" class="d-block text-decoration-none zoom-to-lon-lat" style="color:inherit;" data-country_id="' + value.id_country + '" data-lat="' + value.lat + '" data-lon="' + value.lon + '"><small><img class="country-flag me-1" src="assets/flags/svg/' + value.country_code + '.svg">' + value.country + '</small></a></td></tr>';
    }
  });
  if (lastContinent != false) {
    html += '</table></td></tr>';
  }
  html += '</table>';

  document.getElementById('easydive-markets-body').innerHTML += html;
  /*
  setTimeout(function () {
    document.getElementById('easydive-markets').classList.remove("d-none");
    //document.getElementById('easydive-markets-body').classList.add("show");
  }, 500);
  */
}

function totalsContent() {
  let html = '<table class="first-td-allign-right">';
  let v = indexBase.totals.points;
  document.getElementById('easydive-body-a').getElementsByTagName('div')[0].innerHTML += ' <em><small>(' + v + ')</small></em>';

  //html += '<tr><td>' + v + '</td><td><i class="bi bi-geo-alt-fill text-black"></i> ' + getTranslation('Easydive Point' + ((v > 1) ? 's' : ''));
  //html += '</td></tr>';

  v = indexBase.totals.ranks.Ambassador;
  html += '<tr><td>' + v + '</td><td><i class="bi bi-award-fill text-black"></i> ' + getTranslation('Ambassador' + ((v > 1) ? 's' : '')) + '<a href="#" data-bs-target="#easydive-body-ambassadors" data-bs-toggle="collapse" style="text-decoration:none"><i class="bi bi-arrows-expand text-black ms-1"></i></a></td></tr>';
  html += '<tr><td></td><td id="easydive-body-ambassadors" class="p-0 ps-1 collapse show">'
  html += '<table class="table-compacted">';
  Object.entries(indexBase.totals.categories).forEach(entry => {
    const [key, value] = entry;
    html += '<tr><td class="easydive-body-ambassadors-elements" data-toggle="popover" data-elements="' + escape(JSON.stringify(value)) + '"><small>';
    html += getItemIcon(key, 'me-1 mr-1') + key + '<em style="font-size:0.6rem">(' + Object.keys(value).length + ')</em></small></td></tr>';
  });
  html += '</table>';

  html += '</td></tr>';

  v = Object.keys(indexBase.totals.continents).length;
  html += '<tr><td>' + v + '</td><td><i class="bi bi-globe text-black"></i> ' + getTranslation('Continent' + ((v > 1) ? 's' : '')) + '</td></tr>';

  v = Object.keys(indexBase.totals.countries).length;
  html += '<tr><td>' + v + '</td><td><i class="bi bi-flag-fill text-black"></i> ' + getTranslation('Countr' + ((v > 1) ? 'ies' : 'y')) + '<a href="#" data-bs-target="#easydive-body-countries" data-bs-toggle="collapse" style="text-decoration:none"><i class="bi bi-arrows-expand text-black ms-1"></i></a></td></tr>';
  html += '<tr><td></td><td id="easydive-body-countries" class="p-0 ps-1 collapse show">'
  html += '<table class="table-compacted">';
  Object.entries(indexBase.totals.countries).forEach(entry => {
    const [key, value] = entry;
    html += '<tr><td class="easydive-body-countries-elements" data-toggle="popover" data-elements="' + escape(JSON.stringify(value)) + '"><small><img class="country-flag me-1" src="assets/flags/svg/' + value[2] + '.svg">' + value[1] + '<em style="font-size:0.6rem">(' + value[0] +/*' Easydive Point'+((value>1)?'s':'')*/')</em></small></td></tr>';

  });
  html += '</table>';

  html += '</td></tr>';


  /*
    let html2 = '';
    Object.entries(indexBase.totals.ranks).forEach(entry => {
      const [key, value] = entry;
      console.log(key, value)
      v = parseInt(value);
      if (v > 0 && v != 1) {
        html2 += '<tr><td><small><i class="bi bi-geo-alt text-black"></i> ' + key + '<em style="font-size:0.6rem">(' + value + ')</em></small></td></tr>';
      }
    });
    if (html2 != '') {
      html += '<a href="#" data-bs-target="#easydive-body-points" data-bs-toggle="collapse" style="text-decoration:none"><i class="bi bi-arrows-expand text-black ms-1"></i></a></td></tr>';
  
      html += '<tr><td></td><td id="easydive-body-points" class="p-0 ps-1 collapse">'
      html += '<table class="table-compacted">' + html2 + '</table>';
    }
  */
  html += '</td></tr>';
  html += '</table>';



  document.getElementById('easydive-body').innerHTML += html;
  /*
  setTimeout(function () {
    document.getElementById('easydive-body').classList.add("show")
  }, 500);
  */
}

function getLanguageFromUrl() {
  let urlParams = new URLSearchParams(window.location.search);
  return !!urlParams.get('language') ? urlParams.get('language').substring(0, 2) : 'en';
}
function getItemIcon(value, extraClass, defaultIcon) {
  return '<i class="' + ((typeof extraClass == 'undefined') ? '' : extraClass) + ' bi bi-' + ((value.match('Video')) ? 'camera-video-fill' : ((value.match('Photo')) ? 'camera-reels-fill' : ((value.match('Teck')) ? 'gear-fill' : ((value.match('Free')) ? 'heart-pulse-fill' : ((value.match('Environment')) ? 'asterisk' : ((typeof defaultIcon == 'undefined') ? 'geo-alt-fill' : defaultIcon)))))) + '"></i>';
}
function setClassToClass(toClass, addClass, removeClass){
  Array.from(document.getElementsByClassName(toClass)).forEach(
    function(element, index, array) {
      element.classList.remove(removeClass);
      element.classList.add(addClass);
    }
  );
}
function getPopupTemplate(addClass){
  return '<div class="popover '+addClass+'" role="tooltip"><h3 class="popover-header"></h3><div class="popover-body"></div></div>';
}
class centerControl extends Control {
  /**
   * @param {Object} [opt_options] Control options.
   */
  constructor(opt_options) {
    const options = opt_options || {};
    const button = document.createElement('button');
    button.id = 'centerControl';
    button.innerHTML = '<i class="bi bi-bullseye"></i>';
    button.title = "Center to local position";
    const element = document.createElement('div');
    element.className = 'center-control ol-unselectable ol-control d-none';
    //element.title = "Center to local position";
    //element.dataset.title = "Center to local position";
    //element.dataset.tooltip = "Center to local position";
    //element.setAttribute('data', "bs-original-title: 'Center to local position'");
    element.appendChild(button);
    super({
      element: element,
      target: options.target,
    });
    button.addEventListener('click', this.centerControl.bind(this), false);
  }

  centerControl() {
    //var position = window.geolocation.getPosition();
    fetch('https://nominatim.openstreetmap.org/reverse?format=json&lat=' + window.geolocation.position_[1] + '&lon=' + window.geolocation.position_[0])
      .then(res => res.json())
      .then(out => {
        if (typeof out != 'undefined' && typeof out.address != 'undefined') {
          document.getElementById('centerControl').title = 'Center to local position - ' + out.address.country;
        }
        //console.log('fetched', out);
        /*{
          "place_id": 72325551,
          "licence": "Data © OpenStreetMap contributors, ODbL 1.0. http://osm.org/copyright",
          "osm_type": "way",
          "osm_id": 1113354915,
          "lat": "27.869146153033288",
          "lon": "34.29888287103889",
          "class": "highway",
          "type": "primary",
          "place_rank": 26,
          "importance": 0.10000999999999993,
          "addresstype": "road",
          "name": "Al Salam Road",
          "display_name": "Al Salam Road, Hadabat Om Al Sayed, Old Sharm, Sharm Ash Sheikh, Ras Umm Sid, South Sinai, 46619, Egypt",
          "address": {
              "road": "Al Salam Road",
              "neighbourhood": "Hadabat Om Al Sayed",
              "suburb": "Old Sharm",
              "city": "Sharm Ash Sheikh",
              "district": "Ras Umm Sid",
              "state": "South Sinai",
              "ISO3166-2-lvl4": "EG-JS",
              "postcode": "46619",
              "country": "Egypt",
              "country_code": "eg"
          },
          "boundingbox": [
              "27.8640075",
              "27.8834141",
              "34.2984613",
              "34.3010388"
          ]
      }*/
      })
      .catch(err => { console.log('Fetch error:', err) });
    //console.log('clicked', window.geolocation.getPosition());
    this.getMap().getView().setCenter(window.geolocation.getPosition());
    this.getMap().getView().setZoom(initialZoom);
  }
}
class logoControl extends Control {
  /**
   * @param {Object} [opt_options] Control options.
   */
  constructor(opt_options) {
   const options = opt_options || {};
   const element = document.createElement('div');
   element.id = "logo";
   element.className = 'ol-unselectable ol-control';
   element.style.position = "absolute";
   element.style.top = "10px";
   element.style.width = "100%";
   element.style.maxHeight = "0px";
   element.style.opacity = "0.5";
   element.onmouseout  = function(e){this.style.opacity = "0.5"};
   element.onmouseover = function(e){this.style.opacity = "1"};
   const img = document.createElement("img");
   img.src = "assets/logo/LogoEasydiveTheUniversalHousing.png";
   img.style.display = "block";
   img.style.margin = "0 auto";
   element.appendChild(img);
 
   super({
    element: element,
    target: options.target,
   });
  }
}
/**
 * Init
 */
var clusters = [];
var layers = [
  new TileLayer({
    source: new OSM(),
  })
];
var initialZoom;
var indexBase;
/*
function getIndexbase(callback){
  fetch(dataSource+'index_' + getLanguageFromUrl() + '.json' + '', {
    method: 'GET',
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    }
  })
    .then(res => res.json())
    .then(indexBase => { 
      callback(indexBase);
    });
}
getIndexbase(function(indexBase){
  console.log(indexBase);
  var vectorSourceArray = [];
 
  indexBase.groupsOfPoints.forEach((groupsOfPoint) => {
    var vector = new VectorSource({
      format: new GeoJSON(),
      //object: groupsOfPoint
    });
    var geojsonFormat = new GeoJSON();
    var features = geojsonFormat.readFeatures(groupsOfPoint, {dataProjection: 'EPSG:4326', featureProjection: '4326:3857'});
    //console.log(features, groupsOfPoint);
    var ret = vector.addFeatures( features );
    vectorSourceArray.push( vector );
    //console.log(ret, vector,vectorSourceArray[ vectorSourceArray.length - 1]);
    
  });
});
*/
//var jsonformatter = new GeoJSON();
/**
 * Load data index
 */
//fetch('http://localhost:80/easydivepoints.carbonarm.com/modules/spro_easydive_points/ajax.php?fn='+'index_' + getLanguageFromUrl() + '.json'+'',{
fetch(dataSource+'index_' + getLanguageFromUrl() + '.json', {
  method: 'GET',
  mode: 'no-cors',
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
  }
})
  .then(res => res.json())
  .then(out => {
    indexBase = out;
    //console.log('Checkout this JSON! ', out.last)   
    /*out.groupsOfPoints.forEach((groupsOfPoint) => {
      vectorSource.push( 
        new VectorSource({
          format: new GeoJSON(),
          features: jsonformatter.readFeatures(groupsOfPoint)
        })
      )
      */
    /**
     * Load Point's Clusters
     */
    out.files.forEach((file) => {
      let clusterSource = new Cluster({
        distance: 35,
        source: new VectorSource({
          format: new GeoJSON(),
          //url: 'http://localhost/easydivepoints.carbonarm.com/modules/spro_easydive_points/ajax.php?fn='+file,
          url: dataSource + file,
          //url: 'http://localhost:5173/ajax.php?fn=' + file,
        })
      });
      // Layer displaying the clusters and individual features.
      clusters.push(
        new VectorLayer({
          source: clusterSource,
          style: clusterStyle,
        })
      );
      layers.push(
        new VectorLayer({
          source: clusterSource
        }),
        clusters[clusters.length - 1],
        new VectorLayer({
          source: clusterSource,
          style: clusterCircleStyle,
        })
      );
    });
    /**
     * Build View and Map
     */
    const view = new View({
      constrainResolution: true,
      showFullExtent: true,
      projection: 'EPSG:3857',
      center: [12.341300, 44.222961],
      zoom: 0,
      maxZoom: 16
    })
    const map = new Map({
      layers: layers,
      controls: defaultControls().extend([/*new FullScreen(),*/ new logoControl(), new centerControl(), new ZoomSlider()]),
      target: 'map',
      view: view
    });
    /**
     * Build Legend
     */
    const marketsLayer = marketsPoints();
    const marketsLayerId = getUid(marketsLayer);
    marketsLayer.setVisible(false);
    map.addLayer(marketsLayer);
    /**
     * Populate Features
     */
    document.getElementById('easydive-body-a').innerHTML += '<div class="text-align-center" style="font-size: .5rem;margin-top: 39px;"><em>Updated on ' + indexBase.created + ' - Ver. '+version+'</em></div>'
    setTimeout(function () {
      marketsPointsToggle(map, marketsLayerId, true);
      document.getElementById('easydive-body').addEventListener('hidden.bs.collapse', function () {
        marketsPointsToggle(map, marketsLayerId * -1, false);
      });
      document.getElementById('easydive-body').addEventListener('shown.bs.collapse', function () {
        marketsPointsToggle(map, marketsLayerId * -1, true);
      });
      document.getElementById('easydive-markets-body').addEventListener('hidden.bs.collapse', function () {
        marketsPointsToggle(map, marketsLayerId, false);
      });
      document.getElementById('easydive-markets-body').addEventListener('shown.bs.collapse', function () {
        marketsPointsToggle(map, marketsLayerId, true);
      })
    }, 1000);

    map.addOverlay(popup);
    /*
    const fulls = new FullScreen({
      source: document.getElementById('popup').parentElement
    });
    map.addControl(fulls);
    */
    map.getView().setCenter(fromLonLat([12.341300, 44.222961], 'EPSG:3857', 'EPSG:3857'));
    initialZoom = map.getView().getZoom();
    /*
    //map.zoomToExtent(newBound);
    const newBound = [
      new Point(
      fromLonLat([indexBase.coordinates.min.lon, indexBase.coordinates.min.lat], 'EPSG:3857', 'EPSG:3857')
      ),
      new Point(
      fromLonLat([indexBase.coordinates.max.lon, indexBase.coordinates.max.lat], 'EPSG:3857', 'EPSG:3857')
      )
    ];
    console.log(newBound);
    map.getView().fit(newBound, {
      size: map.getSize(),
      maxZoom: 1
    });
  */
    //getLocation()
    window.geolocation = new Geolocation({
      // enableHighAccuracy must be set to true to have the heading value.
      trackingOptions: {
        enableHighAccuracy: true,
      },
      projection: view.getProjection(),
      tracking: true
    });
    //geolocation.setTracking(true);
    // update the HTML page when the position changes.
    window.geolocation.on('change', function () {
      document.getElementsByClassName("center-control")[0].classList.remove("d-none");
      //console.log(window.geolocation.getPosition());
    });
    // handle geolocation error.
    window.geolocation.on('error', function (error) {
      document.getElementById('info').classList.remove("d-none");
      document.getElementById('info-msg').innerHTML = error.message;
      document.getElementById('info').classList.add("show");
      //console.log(error)
      setTimeout(function () {
        document.getElementById('info').classList.remove("show");
      }, 5000)
    });

    totalsContent();
    marketsContent();

    // Close the popup when the map is moved
    map.on('movestart', disposePopover);

    map.on('pointermove', (event) => {
      if(popoverType == 'ambassador'){
        return;
      }
      let found = false;
      disposePopover();
      if (!found && document.getElementById('easydive-body').classList.toString().match('show')) {
        for (let i = 0; i < clusters.length; i++) {
          found = false;
          let label = '';
          clusters[i].getFeatures(event.pixel).then((features) => {
            features.forEach(feature => {
              feature.get('features').forEach(featur => {
                if (featur.get('TYPE') !== 0) {
                  if (!!featur.get('COUNTRY')) {
                    label += '<div class="d-in line-block m-0 me-1">' + ((label.length == 0) ? '<img class="image-flaga me-1" height="16" src="assets/flags/svg/' + featur.get('COUNTRY_CODE') + '.svg" title="' + featur.get('COUNTRY') +
                      '"><small>' + featur.get('COUNTRY') + '</small><hr class="m-0 mt-1 p-0">' : '') +featur.get('NAME') + '</div>';
                  }
                }
              });
            });
            if (features[0] !== hoverFeature) {
              // Display the convex hull on hover.
              hoverFeature = features[0];
              //clusterHulls.setStyle(clusterHullStyle);
              // Change the cursor style to indicate that the cluster is clickable.
              //console.log('==', hoverFeature);
              map.getTargetElement().style.cursor = 'pointer';
              if (!!hoverFeature && popupElement.innerHTML == '') { //&& hoverFeature.get('features')[0].get('TYPE') == 0){
                disposePopover();
                if (label != '') {
                  popup.setPosition(event.coordinate);
                  popover = new bootstrap.Popover(popupElement, {
                    placement: 'top',
                    html: true,
                    template: getPopupTemplate('country'),
                    content: label
                  });
                  popover.show();
                  popoverType = 'country';
                  setClassToClass('popover','country','ambassador');
                }
              }
              found = true;
              return false;
            }
          })
        }
      }
      if (!found && document.getElementById('easydive-markets-body').classList.toString().match('show')) {
        marketsLayer.getFeatures(event.pixel).then((features) => {
          if (typeof features[0] != 'undefined') {
            if (features[0] !== hoverFeature) {
              disposePopover();
              hoverFeature = features[0];
              let id = hoverFeature.get('id');
              if (id.match('country_')) {
                const cid = id.replace('country_', ''); //country_id = nnn
                const el = document.querySelector('[data-country_id="' + cid + '"]');
                //console.log(el.innerHTML);
                popup.setPosition(event.coordinate);
                popover = new bootstrap.Popover(popupElement, {
                  placement: 'auto', //'right',
                  html: true,
                  content: '<div class="d-in line-block m-0 me-1">' + el.innerHTML + '</div>',
                  template: getPopupTemplate('popover-country')
                  /*'<div class="popover popover-country" role="tooltip"><div class="NOpopover-arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
                  */
                });
                popover.show();
                popoverType = 'popover-country';
                found = true;
                return false;
              }
            }
          }
        });
      }
      if (!found) {
        map.getTargetElement().style.cursor = '';
      }
    });

    map.on('click', (event) => {
      disposePopover();
      for (let i = 0; i < clusters.length; i++) {
        clusters[i].getFeatures(event.pixel).then((features) => {
          if (features.length > 0) {
            const clusterMembers = features[0].get('features');
            if (clusterMembers.length > 1) {
              // Calculate the extent of the cluster members.
              const extent = createEmpty();
              clusterMembers.forEach((feature) =>
                extend(extent, feature.getGeometry().getExtent())
              );
              const view = map.getView();
              const resolution = map.getView().getResolution();
              if (
                view.getZoom() === view.getMaxZoom() ||
                (getWidth(extent) < resolution && getHeight(extent) < resolution)
              ) {
                // Show an expanded view of the cluster members.
                clickFeature = features[0];
                clickResolution = resolution;
                view.fit(extent, { duration: 500, padding: [50, 50, 50, 50] });
                //clusterCircles[i].setStyle(clusterCircleStyle);
              } else {
                // Zoom to the extent of the cluster members.
                view.fit(extent, { duration: 500, padding: [50, 50, 50, 50] });
              }
            } else {
              if (clusterMembers[0].get('TYPE') > 0) {
                //popupElement.classList.remove('country');
                popup.setPosition(event.coordinate);
                popover = new bootstrap.Popover(popupElement, {
                  placement: 'top',
                  html: true,
                  template: getPopupTemplate('ambassador'),
                  content: popupContent(clusterMembers[0])
                });
                popover.show();
                popoverType = 'ambassador';
                setClassToClass('popover','ambassador','country');
              }
            }
          }
        });
      };
    });
    /*
    document.querySelectorAll('.tooltip')
      .forEach(function (el) {
        el.addEventListener('show.bs.tooltip', function () {
        // do something…
        document.querySelectorAll('.tooltip').remove();
      });
    });
    */
    document.querySelectorAll('#easydive-logo, #easydive-markets-logo, .ol-zoom-in, .ol-zoom-out, .ol-rotate-reset, .center-control button')
      .forEach(function (el) {
        new bootstrap.Tooltip(el, {
          container: '#map',
          trigger: 'hover'
        });
      });
    //<button type="button" class="btn btn-lg btn-danger" data-toggle="popover" title="Popover title" data-content="And here's some amazing content. It's very engaging. Right?">Click to toggle popover</button>
    document.querySelectorAll('.easydive-body-countries-elements')
      .forEach(function (el) {
        new bootstrap.Popover(el, {
          trigger: 'hover | focus',
          html: true,
          content: function (e) {
            var content = '';
            var target = JSON.parse(unescape(e.dataset.elements));
            Object.entries(indexBase.groupsOfPoints).forEach(entry => {
              const [key, value] = entry;
              if (value.country == target[1]) {
                Object.entries(value.features.sort((a, b) => (a.properties.NAME > b.properties.NAME) ? 1 : ((b.properties.NAME > a.properties.NAME) ? -1 : 0))).forEach(entryf => {
                  const [keyf, valuef] = entryf;
                  content += getItemIcon(valuef.properties.CATEGORY, 'mr-1 me-1', 'geo-alt-fill');
                  content += valuef.properties.NAME + '<br>';
                });
              }
            });
            return content;
          },
          container: '#map',
        });
      });
    document.querySelectorAll('.easydive-body-ambassadors-elements')
      .forEach(function (el) {
        new bootstrap.Popover(el, {
          trigger: 'hover | focus',
          html: true,
          content: function (e) {
            var content = '';
            Object.entries(JSON.parse(unescape(e.dataset.elements))).forEach(entry => {
              const [key, value] = entry;
              content += '<img class="country-flag me-1" src="assets/flags/svg/' + value.country_code + '.svg" title="' + value.country + '">' + value.name + '<br>';
            });
            return content;
          },
          container: '#map',
        });
      });
    //document.getElementById('pleaserotate-backdrop')
    document.querySelectorAll('#pleaserotate-backdrop')
      .forEach(function (el) {
        el.addEventListener('click', function (eventObj) {
          el.remove();
        });
      });
    document.querySelectorAll('.zoom-to-lon-lat')
      .forEach(function (el) {
        el.addEventListener('click', function (eventObj) {
          map.setView(
            new View({
              center: fromLonLat([el.dataset.lon, el.dataset.lat]),
              zoom: 5
            })
          )
        })
      })
  })
  .catch(err => { throw err });